import * as React from 'react';
import {useState} from 'react';
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import '@bloomreach/banana-theme/css/theme.css';
import '@bloomreach/banana-theme/css/fonts.css';
import {BrPage, BrPageContext} from "@bloomreach/react-sdk";
import axios from "axios";
import {Configuration, TYPE_CONTAINER_ITEM_UNDEFINED, TYPE_CONTAINER_NO_MARKUP} from "@bloomreach/spa-sdk";
import './App.css'
import BrUIContainerItemComponent from "./BrUIContainerItemComponent";
import BrUIContainer from "./BrUIContainer";
import BrUICanvas, {PageWithModel} from "./BrUICanvas";

const theme = createTheme({
    typography: {
        fontFamily: [
            '"Open Sans"',
            'Roboto',
            '"Helvetica Neue"',
            'sans-serif',

        ].join(','),
    },
});


export default function App() {

    const urlParams = new URLSearchParams(window.location.search);
    const [endpoint] = useState(urlParams.get('endpoint'))
    const [token] = useState(urlParams.get('token'))

    return (
        <ThemeProvider theme={theme}>
            <BrPage configuration={{
                path: `${window.location.pathname}${window.location.search}`,
                endpoint: endpoint ?? 'https://sandbox-sales02.bloomreach.io/delivery/site/v1/channels/brxsaas/pages',
                // debug: true,
                httpClient: axios
            } as Configuration} mapping={{
                [TYPE_CONTAINER_ITEM_UNDEFINED]: BrUIContainerItemComponent,
                [TYPE_CONTAINER_NO_MARKUP]: BrUIContainer
            }}>
                <BrPageContext.Consumer>
                    {page => {
                        if (!page) {
                            return <h1>Something went wrong...</h1>
                        }
                        return <BrUICanvas page={page as PageWithModel} endpoint={endpoint} token={token}/>
                    }}
                </BrPageContext.Consumer>
            </BrPage>
        </ThemeProvider>
    );
}

