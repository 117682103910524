import {Component, isContainer, isMenu, Menu as BrMenu, Page} from "@bloomreach/spa-sdk";
import {MenuModels} from "./BrUIMenu";

//@ts-ignore
export function flatten(arr?: [any], parent?: any) {
    return arr ? arr.reduce((result, item) => [
        ...result,
        {
            path: `${parent ? parent + '/' : ''}${item.getName?.()}`,
            type: item.model.type,
            id: item.model.id,
            item: item
        },
        ...flatten(item.getChildren(),
            `${parent ? parent + '/' : ''}${item.getName()}`)
    ], []) : [];
}

//@ts-ignore
export function getMenus(rootComponent: any, page: Page) {
    //@ts-ignore
    return rootComponent ? flatten(rootComponent.getChildren()).map(component => {
        const componentItem: Component = component.item;
        //@ts-ignore
        if (componentItem?.model?.componentClass === "org.hippoecm.hst.component.support.bean.dynamic.MenuDynamicComponent") {
            const menuRef = componentItem?.getModels<MenuModels>()?.menu;
            //@ts-ignore
            const menu = menuRef && page?.getContent<BrMenu>(menuRef);
            return menu
        }
        return componentItem
    }).filter((component: Component) => {
        return isMenu(component)
    }) : []
}

export function getContainers(page: Page) {
    const rootComponent = page.getComponent();
    //@ts-ignore
    return rootComponent ? flatten(rootComponent.getChildren()).filter(component => isContainer(component.item)) : []
}

export function getComponentName(component: Component) {
    //@ts-ignore
    return component?.model?.meta?.pageTitle ?? component?.model?.label ?? component.getName()
}