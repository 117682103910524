import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import RefreshIcon from "@mui/icons-material/Refresh";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import * as React from "react";
import {useEffect, useState} from "react";
import {ToggleButton, ToggleButtonGroup, useTheme} from "@mui/material";

const QRCode = require('qrcode')

function generateRandomString(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({length: 5}, () => characters[Math.floor(Math.random() * characters.length)]).join('');
}

const qrOpts = {
    errorCorrectionLevel: 'L',
    type: 'image/jpeg',
    quality: 0.9,
    margin: 1,
    color: {
        dark: "#fafafa",
        light: "#5E739C"
    }
}

export const PreviewDrawer = ({
                                  qrCode,
                                  open,
                                  drawerWidth,
                                  iframeSrc,
                                  handleDrawerClose,
                                  onResize
                              }: { qrCode?: string | undefined, open: boolean, drawerWidth: number, iframeSrc?: string, handleDrawerClose: () => void, onResize?: (newWidth: number) => void }) => {

    const theme = useTheme()

    const [payload, setPayload] = useState("");
    const [newWidth, setNewWidth] = useState(drawerWidth);
    // const [isResizing, setIsResizing] = useState(false);
    const [id, setID] = useState("")
    const [qrImage, setQrImage] = useState<string | undefined>()
    const [toggle, setToggle] = useState()

    const onMessageReceivedFromIframe = React.useCallback(
        (event: any) => {
            if (event.data.type == 'brxm:event') {
                setPayload(JSON.stringify(event.data.payload))
                setID(event.data.payload.id)
            }
        },
        [payload]
    );

    useEffect(() => {
        window.addEventListener("message", onMessageReceivedFromIframe);
        return () =>
            window.removeEventListener("message", onMessageReceivedFromIframe);
    }, [onMessageReceivedFromIframe]);

    React.useEffect(() => {
        if (payload) {
            console.log("state payload", payload);
        }
    }, [payload]);


    return (<Drawer
        sx={{
            width: newWidth,
            // width: '0',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: newWidth,
                // width: '0',
                boxSizing: 'border-box',
            },
        }}
        variant="persistent"
        anchor="left"
        open={open}

    >
        <Box sx={{paddingTop: '1px'}}>
            <Toolbar variant={"dense"}>
                <Typography noWrap component="div" color={'#666'} variant={"h6"}
                            sx={{flexGrow: 1}}>
                    Preview
                </Typography>
                <ToggleButtonGroup
                    sx={{marginRight: 1}}
                    value={toggle}
                    exclusive
                    onChange={(event, value) => {
                        setToggle(value)
                        if (value) {
                            QRCode.toDataURL(`${qrCode}`, qrOpts, function (err: any, url: string) {
                                setQrImage(url)
                            })
                        } else {
                            setQrImage(undefined)
                        }
                    }}
                >
                    <ToggleButton size={"small"} value="qr">
                        <QrCode2Icon/>
                    </ToggleButton>
                </ToggleButtonGroup>
                <IconButton sx={{marginRight: 1}} onClick={() => setPayload(generateRandomString)}>
                    <RefreshIcon/>
                </IconButton>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </Toolbar>
        </Box>
        <Divider/>
        <Box sx={{height: '100%', position: 'relative'}}>
            {qrImage &&
                <Box>
                    <img style={{
                        width: '80%',
                        height: 'auto',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10%'
                    }} src={qrImage} alt={iframeSrc}/>
                </Box>
            }
            {(!qrImage && iframeSrc) &&
                <iframe key={payload} frameBorder={0} title="preview" style={{width: '100%', height: '100%'}}
                        src={`${iframeSrc}`}/>
            }
        </Box>
    </Drawer>)

}