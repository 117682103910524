import * as React from "react";
import {BrComponentContext} from "@bloomreach/react-sdk";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Card} from "@mui/material";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

export default function BrUIContainerItemComponent() {

    const component = React.useContext(BrComponentContext);
    //@ts-ignore
    const title = component?.model?.label
    return (
        <Card
            sx={{
                backgroundColor: '#fff',
                padding: '6px',
                marginY: 1,
                border: '1px solid #E8E8EA'
            }}>
            <Box display={"flex"} margin={'0 auto'}>
                <ArticleOutlinedIcon fontSize={"medium"} sx={{margin: 'auto 0'}}/>
                <Typography variant="h6" marginLeft={1} component="div">
                    {title}
                </Typography>
            </Box>
        </Card>
    )
}