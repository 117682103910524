import * as React from 'react'
import {useMediaQuery, useTheme} from "@mui/material";
import {BrManageMenuButton, BrPageContext} from "@bloomreach/react-sdk";
import {Menu as BrMenu, Reference} from "@bloomreach/spa-sdk";
import {getMenus} from "./common";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export interface MenuModels {
    menu: Reference;
}

const BrUIMenu = ({open, onMenuOpen}: { open: boolean, onMenuOpen?: (menus: [BrMenu]) => void }) => {
    const page = React.useContext(BrPageContext);

    const theme = useTheme()
    const large = useMediaQuery(theme.breakpoints.up('sm'));
    const shrink = !large || open

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const rootComponent = page?.getComponent();
    //@ts-ignore
    const menus: [BrMenu] = getMenus(rootComponent, page);

    const [enabled, setEnabled] = React.useState(menus && menus.length > 0);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        if (page && page.isPreview()) {
            onMenuOpen && onMenuOpen(menus)
            page.sync();
        }

    };
    const handleClose = () => {
        setAnchorEl(null);
        if (page && page.isPreview())
            //@ts-ignore
            onMenuOpen && onMenuOpen([])
    };


    return (
        <>
            <Button
                sx={{textTransform: 'capitalize'}}
                disabled={!enabled}
                variant="contained"
                onClick={handleClick}
                startIcon={<MenuIcon/>}
            >
                {!shrink && "Menus"}
            </Button>
            <Menu
                sx={{marginTop: 1}}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
            >
                {menus.map((menu: BrMenu, index: number) => {
                    return <MenuItem key={index}
                                     sx={{height: '40px', minWidth: 200, margin: 1, cursor: 'initial'}}
                                     onClick={handleClose}>
                        <BrManageMenuButton menu={menu}/>
                        {//@ts-ignore
                            menu.getName()
                        }
                    </MenuItem>
                })}
            </Menu>
        </>

    )
}

export default BrUIMenu