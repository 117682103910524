import * as React from "react";
import {useEffect, useState} from "react";
import {BrComponent, BrManageContentButton} from "@bloomreach/react-sdk";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Alert, Button, Card, Container} from "@mui/material";
import {AppBar, Main} from "./UI";
import Toolbar from "@mui/material/Toolbar";
import PreviewIcon from "@mui/icons-material/Preview";
import BrUIMenu from "./BrUIMenu";
import {PreviewDrawer} from "./PreviewDrawer";
import {Container as BrContainer, Content, Page} from "@bloomreach/spa-sdk";
import {getComponentName, getContainers} from "./common";

export interface PageWithModel extends Page {
    model?: any
}

export interface Channel {
    __br_xcui_frontend_url: string
    __br_xcui_debug: string
    __br_xcui_preview_width: string
    __br_xcui_appetizeio: string
    __br_xcui_qr_url: string
}

const re = new RegExp('channels\\/(.*)\\/pages');

export default function BrUICanvas({
                                       page,
                                       endpoint,
                                       token
                                   }: { page: PageWithModel, endpoint?: string | null, token?: string | null }) {

    //@ts-ignore
    const channel = re.exec(endpoint)?.length > 0 ? re.exec(endpoint)[1] : 'unknown (paas)';
    const branch = page?.model?.meta?.branch
    const previewOpenStoreName = `${channel}-${branch}-${page.isPreview()}-preview-is-open`
    const [menusOpen, setMenusOpen] = useState<number>(0)

    //@ts-ignore
    const [open, setOpen] = React.useState<boolean>(JSON.parse(localStorage.getItem(previewOpenStoreName)) || false);

    // console.log(page.getChannelParameters())
    const channelParameters = page.getChannelParameters<Channel>();
    const initialWidth = Number(channelParameters?.__br_xcui_preview_width) ?? 576

    const [drawerWidth, setDrawerWidth] = useState(initialWidth)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        localStorage.setItem(previewOpenStoreName, JSON.stringify(open));
    }, [open]);

    const data: Record<string, string> = {
        endpoint, token
    } as Record<string, string>;

    const xpQueryString = new URLSearchParams(data);

    const previewUrl = channelParameters.__br_xcui_frontend_url ?
        `${channelParameters.__br_xcui_frontend_url}${page.getUrl()}?${xpQueryString}`
        : channelParameters.__br_xcui_appetizeio ?
            `${channelParameters.__br_xcui_appetizeio}&params=${encodeURI(JSON.stringify({
                ...data,
                path: page.getUrl()
            }))}` : undefined

    const qrCode = channelParameters.__br_xcui_qr_url ? `${channelParameters.__br_xcui_qr_url}${page.getUrl()}?${xpQueryString}` : previewUrl

    // console.log(previewUrl)

    //@ts-ignore
    const containers = getContainers(page)

    const document = page.getDocument();

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="fixed" variant={"outlined"} color={"transparent"}
                    sx={{backgroundColor: '#fafafa'}}
                    open={open} drawerWidth={drawerWidth}>
                <Toolbar variant={"dense"}>
                    <Button
                        disabled={!previewUrl}
                        variant={"outlined"}
                        onClick={handleDrawerOpen}
                        color="inherit"
                        aria-label="open preview"
                        sx={{mr: 1, textTransform: 'capitalize', ...(open && {display: 'none'})}}
                        startIcon={<PreviewIcon/>}
                    >
                        Preview
                    </Button>
                    <Typography variant="h6" noWrap component="div" sx={{ml: 1, flexGrow: 1}}>
                        {page &&
                            //@ts-ignore
                            getComponentName(page.getComponent())}
                    </Typography>


                    <BrUIMenu open={open} onMenuOpen={menus => setMenusOpen(menus.length)}/>

                    {/*<IconButton*/}
                    {/*    color="inherit"*/}
                    {/*    aria-label="info dialog"*/}
                    {/*    edge="end"*/}
                    {/*>*/}
                    {/*    <InfoIcon/>*/}
                    {/*</IconButton>*/}
                </Toolbar>
            </AppBar>
            {/*<Box>*/}
            {/*    <>*/}
            {/*        {document && <Box position={'fixed'} top={5} right={5}>*/}
            {/*            <Box position={"relative"}> </Box>*/}
            {/*        </Box>}*/}
            {/*    </>*/}
            {/*</Box>*/}

            <PreviewDrawer open={open} qrCode={qrCode} drawerWidth={drawerWidth}
                           iframeSrc={`${previewUrl}`}
                           handleDrawerClose={handleDrawerClose}/>
            <Main open={open} drawerWidth={drawerWidth} >
                <Box sx={{height: `${40 + (menusOpen * 40)}px`}}/>

                <Container>
                    {//@ts-ignore
                        containers.map((item, index) => {

                            const containerModel: BrContainer = item.item;

                            return <Box key={index} position={'relative'}>
                                <Box  marginTop={'-10px'}>  <BrManageContentButton
                                    content={document as Content}/></Box>

                                <Typography variant={"h6"}
                                            marginY={1}>{
                                    //@ts-ignore
                                    getComponentName(containerModel)

                                }</Typography>
                                <Card elevation={1} sx={{
                                    backgroundColor: '#fafafa',
                                    padding: '5px 10px',
                                    minHeight: '50px',
                                    marginBottom: 2
                                }}>
                                    {
                                        !(containerModel.getChildren() && containerModel.getChildren().length > 0) &&
                                        <Alert sx={{margin: 2}} variant={"outlined"}
                                               severity={"error"}>
                                            <Typography>This is an empty container, components
                                                can be added here from the left side "Components" drawer!</Typography>
                                        </Alert>
                                    }
                                    <BrComponent path={item.path}/>
                                </Card>
                            </Box>
                        })}
                </Container>
            </Main>
        </Box>
    )
}