import * as React from "react";
import {BrComponent, BrComponentContext} from "@bloomreach/react-sdk";

export default function BrUIContainer() {

    const container = React.useContext(BrComponentContext);

    return (
        <>
            {
                container?.getChildren() &&
                <BrComponent/>
            }
        </>)
}